import {get} from "./vrexAuth";
import {proxyEncode} from "../common/util";

class AconexApi {

    static async getProjects() {
        return await get(process.env.REACT_APP_INTEGRATION_PROXY_URL + "/aconex/api/projects")
    }

    static async getDocuments(projectId) {
        return await get(proxyEncode(process.env.REACT_APP_INTEGRATION_PROXY_URL + "/aconex/api/projects/" + projectId + "/register?search_query=filetype:ifc OR filetype:nwc OR filetype:nwd OR filetype:rvt OR filetype:e57&return_fields=filename"), { Accept: "application/xml" })
    }
}

export default AconexApi;
